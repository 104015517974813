/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import "./styles.scss"

import React, { useState, useEffect, useContext } from "react"
import Slider from "react-slick"
import { useMutation, useQuery } from "@apollo/client"
import { v4 } from "uuid"
// import Lightbox from "react-image-lightbox"
import Tippy from "@tippyjs/react"
import { Link } from "gatsby"

import InvestBaner from "components/InvestBaner"
import Button from "components/Button"
import Radio from "components/Radio"
import { AddToCart } from "./components"
// import InvestPopup from "components/InvestPopup"

import { AppContext } from "context/app_context"

import GET_CART from "queries/get-cart"
import CLEAR_CART_MUTATION from "mutations/clear-cart"
import { getFormattedCart } from "utils/functions"

// import IcoMinus from "assets/icons/minus.svg"
// import IcoPlus from "assets/icons/plus.svg"
import IcoInfo from "assets/icons/info-dark.svg"
import Calculator from "./components/Calculator"

const Content = ({ data }) => {
  const [cart, setCart] = useContext(AppContext)
  const [popup, setPopup] = useState(false)
  const [popupBlocked, setPopupBlocked] = useState(true)
  const [productQuantity, setProductQuantity] = useState(1)
  const [productPrice, setProductPrice] = useState(5000)
  const [productVariation, setProductVariation] = useState(null)
  const [interestRateTab, setInterestRateTab] = useState("variable")
  const productName = data?.name

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  // const rmQuantity = ({ price }) => {
  //   setProductPrice(
  //     parseInt(price) * (productQuantity === 1 ? 1 : productQuantity - 1)
  //   )
  //   setProductQuantity(productQuantity === 1 ? 1 : productQuantity - 1)
  // }

  // const addQuantity = ({ price }) => {
  //   setProductPrice(parseInt(price) * (productQuantity + 1))
  //   setProductQuantity(productQuantity + 1)
  // }

  // Get Cart Data.
  const { dataFetch, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(dataFetch)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  // Update Cart Mutation.
  const [clearCart, { loading: clearCartProcessing }] = useMutation(
    CLEAR_CART_MUTATION,
    {
      onCompleted: () => {
        refetch()
      },
      onError: error => {
        if (error) {
          console.warn(error.graphQLErrors[0].message)
        }
      },
    }
  )

  // Clear the entire cart.
  const handleClearCart = () => {
    if (clearCartProcessing) {
      return
    }

    clearCart({
      variables: {
        input: {
          clientMutationId: v4(),
          all: true,
        },
      },
    })
  }

  useEffect(() => {
    handleClearCart()
  }, [])

  const contentSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  const contentData = {
    popup,
    setPopup,
    productPrice,
    productName,
    popupBlocked,
    setPopupBlocked,
  }

  const gallery_slider = [
    require("assets/images/invest/Galeria_1.jpg").default,
    require("assets/images/invest/Galeria_2.jpg").default,
    require("assets/images/invest/Galeria_3.jpg").default,
    require("assets/images/invest/Galeria_4.jpg").default,
    require("assets/images/invest/Galeria_5.jpg").default,
    require("assets/images/invest/Galeria_6.jpg").default,
    require("assets/images/invest/Galeria_7.jpg").default,
    require("assets/images/invest/Galeria_8.jpg").default,
    require("assets/images/invest/Galeria_9.jpg").default,
    require("assets/images/invest/Galeria_10.jpg").default,
    require("assets/images/invest/Galeria_11.jpg").default,
    require("assets/images/invest/Galeria_12.jpg").default,
  ]

  const tooltipData = data => {
    return (
      <span className="tooltip-hero">
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </span>
    )
  }

  return (
    <section className="investment-content">
      <div className="container-fluid">
        <div className="investment-content__top">
          <img
            src={require("assets/images/invest/logo-sky-trust.svg").default}
            alt=""
          />
          <address>
            <strong>Kielce</strong>
            Al. Solidarności
          </address>
          <h4>w realizacji</h4>
        </div>

        <div className="row">
          <div className="col-xl-8">
            <div className="investment-content__slider">
              <Slider {...contentSlider}>
                {gallery_slider?.map((item, index) => (
                  <div key={index}>
                    <div
                      className="investment-content__slider-item"
                      style={{
                        backgroundImage: `url('${item}')`,
                      }}
                    ></div>
                  </div>
                ))}
              </Slider>
            </div>

            <div className="investment-content__buy investment-content__buy--mobile">
              <h4>Kwota inwestycji</h4>
              <div className="investment-content__input">
                <div>
                  <input
                    min={5000}
                    type="number"
                    value={productPrice}
                    onChange={e => setProductPrice(e.target.value)}
                    onFocus={() => {
                      setProductPrice("")
                      setProductVariation(null)
                    }}
                    onBlur={() => {
                      setProductPrice(
                        Math.round(productPrice / 5000) * 5000 < 5000
                          ? 5000
                          : Math.round(productPrice / 5000) * 5000
                      )
                      setProductQuantity(
                        Math.round(parseInt(productPrice) / 5000)
                      )
                    }}
                  />{" "}
                  zł
                </div>
                <small>
                  Wprowadzona wartośc zostanie zaokrąglona do wielokrotności
                  kwoty 5000 zł.
                </small>
              </div>
              {/* <p>
                Liczba udziałów: <strong>{productQuantity}</strong>
              </p> */}
              <h4>Typ oprocentowania</h4>
              <div className="investment-content__radio">
                <Radio
                  // name="audience"
                  value={productVariation}
                  onChange={() =>
                    parseInt(productPrice) >= 30000 && setProductVariation(1786)
                  }
                  checked={productVariation === 1786}
                  disabled={parseInt(productPrice) < 30000}
                >
                  Projekt 14 - Oprocentowanie stałe{" "}
                  <Tippy
                    content={tooltipData(
                      "Oprocentowanie stałe dostępne<br/> od kwoty 30 000 zł."
                    )}
                    trigger="mouseenter focus"
                    placement="top"
                  >
                    <img src={IcoInfo} alt="" />
                  </Tippy>
                </Radio>
                <Radio
                  // name="audience"
                  value={productVariation}
                  onChange={() => setProductVariation(1788)}
                  checked={productVariation === 1788}
                >
                  Projekt 14 - Oprocentowanie zmienne
                </Radio>
              </div>
              <div className="investment-content__buy-cta">
                <AddToCart
                  product={data}
                  quantity={productQuantity}
                  productVariation={productVariation}
                />
                <Button
                  onClick={() => setPopup(true)}
                  className="button--transparent"
                >
                  Zapytaj o szczegóły
                </Button>
              </div>
            </div>

            <div className="investment-content__table">
              <h3>Estymowany zysk*</h3>

              <div className="investment-content__table-wrapper">
                <div className="investment-content__table-header">
                  <div>
                    <p>Nasza propozycja</p>
                    <h4>Trust Investment Projekt 14</h4>
                    <p>(Sky Trust etap II)</p>
                  </div>
                </div>

                <div className="investment-content__table-row">
                  <div>
                    Oprocentowanie zmienne
                    <h4>10,60%*</h4>
                    (WIBOR 3M + 4,75 p.p.)
                  </div>
                  <div>
                    Oprocentowanie stałe
                    <h4>10%</h4>
                  </div>
                </div>
              </div>

              <small>
                * Inwestowanie wiąże się z ryzykiem. Informacja o ryzykach:{" "}
                <Link to="/ryzyka/">Ryzyka</Link>
              </small>
            </div>

            <h3>Informacje ogólne</h3>
            <div className="investment-content__info">
              <div className="row">
                {/* <div className="col-sm-6">
                  <p>Do końca</p>
                  <h4>-</h4>
                </div> */}
                {/* <div className="col-sm-6">
                  <p>Wartość jednego udziału</p>
                  <h4>2 000 zł</h4>
                </div> */}
              </div>
            </div>

            <div className="investment-content__details">
              <h4>Stopa zwrotu inwestycji</h4>

              <div className="row">
                <div className="col-6">
                  <p>
                    <strong>Oprocentowanie zmienne</strong>
                  </p>
                  <h4>
                    <strong>10,60%*</strong>
                  </h4>
                  <small>
                    * Oprocentowanie zostało przyjęte na dzień 01.04.2025 roku,
                    przy założeniu, że na dzień 01.04.2025 roku WIBOR 3M wynosi
                    5,85%, a marża 4,75%. Oprocentowanie zmienne ustalane jest w
                    oparciu o zmienną stopę bazową WIBOR 3M oraz stałą marżę. Do
                    zmiennej stopy procentowej dodawana będzie stała marża w
                    wysokości 4,75%. Ostateczna wartość szacowanych zysków może
                    więc różnic się od tej zaprezentowanej w wyliczeniach.
                  </small>
                </div>

                <div className="col-6">
                  <p>
                    <strong>Oprocentowanie stałe</strong>
                  </p>
                  <h4>
                    <strong>10%</strong>
                  </h4>
                </div>

                <div className="col-6">
                  <p>Minimalna kwota inwestycji</p>
                  <h4>5 000 zł</h4>
                  <p>Opodatkowanie</p>
                  <h4>19% od dochodu</h4>
                  <p>Koszty dodatkowe</p>
                  <h4>Brak</h4>
                </div>

                <div className="col-6">
                  <p>Minimalna kwota inwestycji</p>
                  <h4>30 000 zł</h4>
                  <p>Opodatkowanie</p>
                  <h4>19% od dochodu</h4>
                  <p>Koszty dodatkowe</p>
                  <h4>Brak</h4>
                </div>
              </div>

              <div className="investment-content__details-wrapper">
                <div className="row">
                  <div className="col-sm-6">
                    <p>Forma inwestycji</p>
                    <h4>Pożyczka inwestycyjna</h4>
                  </div>
                  <div className="col-sm-6">
                    <p>Horyzont inwestycji</p>
                    <h4>24 miesiące</h4>
                  </div>
                  {/* <div className="col-sm-6">
                    <p>Bezobsługowość</p>
                    <h4>
                      Wizyta u notariusza w przypadku inwestycji powyżej 30
                      000,00 zł
                    </h4>
                  </div> */}
                  <div className="col-sm-6">
                    <p>Wypłata odsetek</p>
                    <h4>co 3 miesiące</h4>
                  </div>
                  <div className="col-sm-6">
                    <p>Zabezpieczenie spłaty pożyczki</p>
                    <h4>
                      Pożyczki w kwocie wyższej lub równej 30.000,00 zł są
                      zabezpieczone Oświadczeniem o poddaniu się Spółki Trust
                      Investment Projekt 14 sp. z o.o. egzekucji w trybie art.
                      777 § 1 pkt. 5 k.p.c. oraz Umową Poręczenia przez Trust
                      Investment Spółka Akcyjna wraz z zabezpieczającym ją
                      Oświadczeniem Poręczyciela o poddaniu się egzekucji w
                      trybie art. 777 § 1 pkt. 5 k.p.c.
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <h3>Harmonogram inwestycji</h3>
            <div className="investment-content__chart">
              <div className="investment-content__chart-tabs">
                <button
                  className={interestRateTab === "variable" ? "current" : ""}
                  onClick={() => setInterestRateTab("variable")}
                >
                  Oprocentowanie zmienne
                </button>
                <button
                  className={interestRateTab === "pernament" ? "current" : ""}
                  onClick={() => setInterestRateTab("pernament")}
                >
                  Oprocentowanie stałe
                </button>
              </div>

              <div className="investment-content__chart-wrapper">
                <img
                  src={
                    require("assets/images/invest/sky-trust-chart-clean-v2.svg")
                      .default
                  }
                  alt=""
                />
                {interestRateTab === "variable" ? (
                  <>
                    <h4>
                      10,60%<sup>1</sup>
                      <span>WIBOR 3M + MARŻA 4,75%</span>
                    </h4>
                    <h4>
                      10,60%<sup>1</sup>
                      <span>WIBOR 3M + MARŻA 4,75%</span>
                    </h4>
                  </>
                ) : (
                  <>
                    <h4>10%</h4>
                    <h4>10%</h4>
                  </>
                )}
              </div>
            </div>

            <h3>Business plan</h3>
            <div className="investment-content__plan">
              <p>
                Estymowane przychody <strong>141 469 091,00 zł</strong>
              </p>
              <p>
                Estymowany łączny koszt <strong>96 287 119,00 zł</strong>
              </p>
              <h4>
                <span>Estymowany zysk</span>
                <strong>45 181 972,00 zł</strong>
              </h4>
            </div>

            <h3>Kalkulator estymowanego zysku*</h3>
            <Calculator
              interestRateTab={interestRateTab}
              setInterestRateTab={setInterestRateTab}
            />

            <h3>Opis inwestycji</h3>
            <div className="investment-content__description">
              <p>
                Sky Trust etap II to inwestycja, która znacząco zmieni krajobraz
                Kielc - będąc najwyższym budynkiem w regionie staje się symbolem
                nowoczesności i zrównoważonego rozwoju. Jest to innowacyjne
                przedsięwzięcie odpowiadające potrzebom lokalnej społeczności
                oraz wspierające rozwój lokalnego biznesu. Łączy funkcje
                mieszkalne (111 apartamentów), komercyjne (86 lokali
                inwestycyjnych i 36 lokali usługowych) i rekreacyjne. Dla
                mieszkańców przygotowano szereg udogodnień takich jak: zapach i
                muzyka w holu, prywatny taras widokowy, prywatne paczkomaty,
                prywatny gaming room czy prywatna sala kinowa. Wyjątkowość
                inwestycji potęguje fantastyczny projekt wnętrz przygotowany
                przez duet kreatorów mody - Paprocki Brzozowski. To wszystko
                sprawia, że Sky Trust to coś więcej niż kompleks budynków – to
                nowoczesne centrum życia miejskiego, które wzbogaci Kielce,
                oferując mieszkańcom nowe możliwości i podnosząc jakość życia w
                mieście.
              </p>
              <p>
                Lokalizacja inwestycji jest jej kolejnym, wielkim atutem - dwa
                kilometry od Rynku, ulicy Sienkiewicza czy Zalewu Kieleckiego,
                500 metrów od Galerii Echo, Kina Helios, tuż obok wydziałów
                Uniwersytetu Jana Kochanowskiego. Na miejscu znajdują się
                sklepy, szkoły, komunikacja miejska, a nieopodal węzeł
                komunikacyjny dróg krajowych 73 i 74 Kraków - Kielce - Warszawa.
                Sky Trust to centrum życia lokalnego z fenomenalnym połączeniem
                z Polską i całym światem.
              </p>
            </div>

            {/* <h3>Zdjęcia z budowy</h3>
            <div className="investment-content__table">
              <div className="row">
                {gallery?.map((item, index) => (
                  <div className="col-lg-4 col-sm-6" key={index}>
                    <a
                      href="/"
                      className="portfolio-gallery__item portfolio-gallery__item--lower"
                      onClick={e => {
                        e.preventDefault()
                        setIsLightboxOpen(true)
                        setPhotoIndex(index)
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url('${item}')`,
                        }}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div> */}
          </div>

          <div className="col-xl-4">
            <div className="investment-content__buy">
              <h4>Kwota inwestycji</h4>
              <div className="investment-content__input">
                <div>
                  <input
                    min={5000}
                    type="number"
                    value={productPrice}
                    onChange={e => {
                      setProductPrice(e.target.value)
                    }}
                    onFocus={() => {
                      setProductPrice("")
                      setProductVariation(null)
                    }}
                    onBlur={() => {
                      setProductPrice(
                        Math.round(productPrice / 5000) * 5000 < 5000
                          ? 5000
                          : Math.round(productPrice / 5000) * 5000
                      )
                      setProductQuantity(
                        Math.round(parseInt(productPrice) / 5000)
                      )
                    }}
                  />{" "}
                  zł
                </div>
                <small>
                  Wprowadzona wartośc zostanie zaokrąglona do wielokrotności
                  kwoty 5000 zł.
                </small>
              </div>
              {/* <p>
                Liczba udziałów: <strong>{productQuantity}</strong>
              </p> */}
              <h4>Typ oprocentowania test</h4>
              <div className="investment-content__radio">
                <Radio
                  // name="audience"
                  value={1786}
                  onChange={() =>
                    parseInt(productPrice) >= 30000 && setProductVariation(1786)
                  }
                  checked={productVariation === 1786 ? true : false}
                  disabled={parseInt(productPrice) < 30000}
                >
                  Projekt 14 - Oprocentowanie stałe{" "}
                  <Tippy
                    content={tooltipData(
                      "Oprocentowanie stałe dostępne<br/> od kwoty 30 000 zł."
                    )}
                    trigger="mouseenter focus"
                    placement="top"
                  >
                    <img src={IcoInfo} alt="" />
                  </Tippy>
                </Radio>
                <Radio
                  // name="audience"
                  value={1788}
                  onChange={() => setProductVariation(1788)}
                  checked={productVariation === 1788 ? true : false}
                >
                  Projekt 14 - Oprocentowanie zmienne
                </Radio>
              </div>
              <div className="investment-content__buy-cta">
                <AddToCart
                  product={data}
                  quantity={productQuantity}
                  productVariation={productVariation}
                />
                <Button
                  onClick={() => setPopup(true)}
                  className="button--transparent"
                >
                  Zapytaj o szczegóły
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InvestBaner {...contentData} />
      {/* <InvestPopup {...contentData} /> */}

      {/* {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )} */}
    </section>
  )
}

export default Content
